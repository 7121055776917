<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Testimonials List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['testimonials/create'])"
                       :to="'/testimonials/create/'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :sortable="true"
            :method="'POST'"
            :api-url="'testimonials/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

             <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['services/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['testimonials/update']) ||
                                     $store.getters['auth/checkAccessOwner']( scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/testimonials/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>
                <router-link   v-if="$store.getters['auth/checkAccess']('testimonials/get')"
                              v-tooltip.top-center="'Show'"
                              class="btn-warning btn-simple btn-link"
                              :to="'/testimonials/show/'+scope.row.id">
                  <i class="fa fa-eye"></i>
                </router-link>
              </template>

                <a v-if="$store.getters['auth/checkAccess']('testimonials/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this request?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>



    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";


export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Message', value: 'message', minWidth: '200', align: 'center'},
        {label: 'Source', value: 'source', minWidth: '200', align: 'center'},
        {label: 'source Info', value: 'source_info', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },

      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL
    }

  },
  created() {
      this.responsiveViewPort();
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("testimonials/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Job updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("testimonials/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Request deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
        }else{
            this.fixedColumn = 'right';
        }
    }
  },
}
</script>

