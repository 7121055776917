var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sectionForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 com-sm-12 mb-2 mt-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Message")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"source","rules":"required","name":"The Source"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Source","name":"source","fou":""},model:{value:(_vm.formData.source),callback:function ($$v) {_vm.$set(_vm.formData, "source", $$v)},expression:"formData.source"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"source_info","rules":"required","name":"The Source Info"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Source Info","name":"source_info","fou":""},model:{value:(_vm.formData.source_info),callback:function ($$v) {_vm.$set(_vm.formData, "source_info", $$v)},expression:"formData.source_info"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/testimonials/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }